









































import TopBar from "@/components/common/TopBar.vue";
import Widget from "@/components/common/Widget.vue";

import OPOSDebitoren from "@/components/DashboardItems/OPOSDebitoren.vue";
import OPOSKreditoren from "@/components/DashboardItems/OPOSKreditoren.vue";
import Liquiditaetsstatus from "@/components/DashboardItems/Liquiditaetsstatus.vue";
import OPOS from "@/components/DashboardItems/OPOS.vue";
import OPOSUebersichtstabelle from "@/components/DashboardItems/OPOSUebersichtstabelle.vue";
import Vue from "vue";
import Component from "vue-class-component";
import api from "../../api";
import store from "../../store";

import { Watch } from "vue-property-decorator";
import { oposBarCharts, chartLiquiditatsstatus } from "../../helper/charts";

@Component({
  components: {
    TopBar,
    Widget,
    OPOSUebersichtstabelle,
    OPOSDebitoren,
    OPOSKreditoren,
    OPOS,
    Liquiditaetsstatus,
  },
})
export default class Opoes extends Vue {
  initPage = false;

  get watchData() {
    return {
      client: store.getters.selectedClient,
      year: store.getters.selectedYear,
    };
  }

  get oposData() {
    return {
      deb: store.getters.oposDeb,
      kred: store.getters.oposKred,
      resize: store.getters.resize,
    };
  }

  get curves() {
    return {
      curves: store.getters.oposCurves,
      resize: store.getters.resize,
    };
  }

  get topBarNavigation() {
    return {
      year: store.getters.selectedYear,
      client: store.getters.selectedClient,
    };
  }

  get biAgency() {
    return store.getters.selectedBiAgency;
  }

  pushStateHistory(stb: any, mandant: any, year: any, range: any) {
    const selectedStb = this.biAgency != null ? this.biAgency : stb;

    let path =
      "/" +
      this.$route.name +
      "/" +
      encodeURIComponent(selectedStb) +
      "/" +
      encodeURIComponent(mandant) +
      "/" +
      encodeURIComponent(year);

    if (this.$route.path !== path) {
      this.$router.replace(path);
    }
  }

  mounted() {
    this.initPage = true;
    this.onDataChange();
    this.onOposDataChange();
    this.onCurvesChange();
  }

  @Watch("topBarNavigation")
  onTopBarNavigationChange() {
    // change URL based on seleted parameters in top bar
    let mandant: string = this.topBarNavigation.client[1];
    let year: string = this.topBarNavigation.year[0];
    let stb: string =
      this.topBarNavigation.client && this.topBarNavigation.client[0];

    this.pushStateHistory(stb, mandant, year, store.getters.rangeStep);
  }

  @Watch("watchData")
  onDataChange() {
    if (this.watchData.client && this.watchData.year) {
      store.dispatch("updateLoading", store.state.loading + 3);

      api
        .getOposDeb(this.watchData.client, this.watchData.year)
        .then((res) => {
          store.dispatch("updateOposDeb", res.data);
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateOposDeb", null);
          store.dispatch("updateLoading", store.state.loading - 1);
        });

      api
        .getOposKred(this.watchData.client, this.watchData.year)
        .then((res) => {
          store.dispatch("updateOposKred", res.data);
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateOposKred", null);
          store.dispatch("updateLoading", store.state.loading - 1);
        });

      /// DUPLICATE CALL OF GET OPPOS TABLE
      // api.getOposFilters(this.watchData.client, this.watchData.year).then(res => {
      //   store.dispatch('updateOposFilters', res.data)
      //   store.dispatch('updateLoading', store.state.loading - 1)
      // }).catch(err => {
      //   store.dispatch('updateOposFilters', null)
      //   store.dispatch('updateLoading', store.state.loading - 1)
      // })
      api
        .getOposCurves(this.watchData.client, this.watchData.year)
        .then((res) => {
          store.dispatch("updateOposCurves", res.data);
          store.dispatch("updateLoading", store.state.loading - 1);
        })
        .catch((err) => {
          store.dispatch("updateOposCurves", null);
          store.dispatch("updateLoading", store.state.loading - 1);
        });

      if (this.initPage) {
        this.onTopBarNavigationChange();
        this.initPage = false;
      }
    }
  }

  @Watch("oposData")
  onOposDataChange() {
    if (this.oposData.deb && this.oposData.kred) {
      oposBarCharts(this.oposData.deb, this.oposData.kred);
    }
  }

  @Watch("curves")
  onCurvesChange() {
    if (this.curves.curves) {
      chartLiquiditatsstatus(this.curves.curves, 90);
    }
  }

  setRange(e: any) {
    chartLiquiditatsstatus(this.curves.curves, e);
  }
}
